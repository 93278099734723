import Images from './Images';

const load = (...i) =>
	Promise.all(i).then(f => f.map(f => new f.default()));

(async function () {
	window.lazy = new Images();

	document.getElementById('toggleNav').addEventListener('click', e => {
		e.preventDefault();
		document.body.classList.toggle('nav-open');
	});

	switch (window.p) {
		case 'index':
			await load(import('./Index'));
			break;
		case 'about':
			await load(
				import('./Team'),
				import('./Modal'),
			);
			break;
		case 'job':
			await load(import('./Job'));
			break;
		case 'news':
			await load(import('./News'));
			break;
		case 'partners':
			await load(
				import('./Partners'),
				import('./Modal'),
			);
			break;
		case 'tech':
			await load(import('./Tech'));
			break;
		case 'careers':
			await load(
				import('./Job'),
				import('./Modal'),
			);
			break;
	}
})();
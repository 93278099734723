import Lazy from "ether-lazy";

export default class Images {

	// Properties
	// =========================================================================

	cache = [];
	lazy  = null;

	constructor () {
		this.lazy = new Lazy();
		this.handleImages();
	}

	// Actions
	// =========================================================================

	handleImages () {
		const imgs = document.querySelectorAll("img.lazy");

		for (let i = 0, l = imgs.length; i < l; ++i) {
			const img = imgs[i];
			const key = img.dataset.src;

			if (!~this.cache.indexOf(key)) {
				img.addEventListener("load", () => {
					this.cache.push(key);
				});
			} else {
				img.classList.remove("lazy");
				img.setAttribute("src", img.dataset.src);
				if (img.dataset.srcset)
					img.setAttribute("srcset", img.dataset.srcset);
			}
		}
	}

	update () {
		this.handleImages();
		this.lazy.init();
	}

}